* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #eee;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    line-height: 1.4;
}

a {
    color: #134FE6;
    text-decoration: none;
}

a:hover {
    color: #05ff56;
}

p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 17px;
    line-height: 30px;
}

h1 {
    font-family: "Lora", Georgia, Cambria, "Times New Roman", Times, serif;
}

h2 {
    font-family: "Lora", Georgia, Cambria, "Times New Roman", Times, serif;
}

.left {
    border: solid 0px #f00;
    width: 640px;
    float: left;

    padding-left: 0.625em;
    padding-right: 0.625em;
}

.right {
    border: solid 0px #00f;
    width: 300px;
    float: right;
}

.container {
    max-width: 960px;
    margin: auto;
}

.panel {
    background-color: #fff;
    padding: 2em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    margin-bottom: 15px;
}


/*begin header*/
.header-container {
    color: #ccc;
    background-color: #3B3F51;
}

.home-header-container {
    padding-top: 200px;
    padding-bottom: 20px;
    color: #ccc;
    background-color: #3B3F51;
}
/*end header*/

/*begin top banner*/
.banner-container {
    background-color: #E1E1E3;
}

.banner-content {
    margin: auto;
    max-width: 970px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.banner-mock {
    width: 728px;
    height: 90px;
    border: solid 1px #000;
    margin-left: auto;
    margin-right: auto;
}

.brectangle-content {
    margin-bottom: 15px;
}

.brectangle-mock {
    width: 300px;
    height: 250px;
    border: solid 1px #000;
    margin-left: auto;
    margin-right: auto;
}
/*end top banner*/

.history-container {
    clear: both;
}


.searchbar-content {
    margin: auto;
    max-width: 880px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.input-group {
    /*border: solid 1px #0f0;*/
    display: table;
    width: 100%;
}

.input-group-main {
    /*border: solid 1px #f00;*/
    width: 100%;
    max-width: 740px;
    display: table-cell;
}

.input-text {
    font-size: 18px;
    height: 40px;
    /*border-radius: 4px 0 0 4px;*/
    box-shadow: none;
    outline: none;
    border: solid 1px #ddd;
    padding: 0 10px;
}

.input-group-side {
    /*border: solid 1px #0ff;*/
    width: 1%;
    display: table-cell;
}

.input-button {
    box-shadow: none;
    outline: none;
    padding: 0 20px;
    font-size: 17px;
    height:40px;
    color: #fff;
    background-color: #5cb85c;
    border: solid 1px #4cae4c;
    /*border-radius: 0 4px 4px 0;*/
    display: inline-block;
    white-space: nowrap;
}

/*top track component links*/
.popular {
    font-size: 16px;
    line-height: 28px;
}

/*ul/li without bullets*/
.no-bullet {
    list-style-type: none;
}

/*search classes*/
#search-form-dropdown {
    z-index: 1;
}

.search-form-dropdown-item {
    height: 40px;
    font-size: 18px;
    background: #fff;
    color: #606060;
    border: solid 1px #ddd;
    border-top: 0;
    padding: 6px 10px;
    cursor: default;
    overflow: hidden;
    white-space: nowrap;
}

.item-selected {
    background: #eee;
}

.text-center {
    text-align: center;
}

.text-sm {
    font-size: 12px;
}

.panel-header {
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    border-bottom: solid 1px #000;
    margin-bottom: 10px;
}

.panel-button {
    font-family: "Lora", Georgia, Cambria, "Times New Roman", Times, serif;
    display: block;
    text-align: center;
    margin-bottom: 15px;
    font-size: 24px;
    color: #fff;
    background-color: #134FE6;
    padding: 15px;
    font-weight: 700;
    border: solid 1px #000;
    border-bottom: solid 2px #000;
}

.panel-button:hover {
    color: #fff;
    background-color: #5cb85c;
    border: solid 1px #4cae4c;
}

.letterlinks-content {
    text-align: center;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.home-letterlink {
    margin: 4px;
    padding: 4px;
    font-size: 20px;
    font-weight: bold;
    color: #aaa;
}

.home-letterlink:hover {
    margin: 4px;
    padding: 4px;
    font-size: 20px;
    font-weight: bold;
    color: #ddd;
    text-decoration: underline;
}

.link2 {
    color: #aaa;
    text-decoration: underline;
}

.link2:hover {
    color: #ddd;
    text-decoration: underline;
}

@media screen and (max-width: 900px) {
    .home-header-container {
        padding-top: 40px;
    }

    .input-text {
        margin-left: 10px;
    }

    .input-button {
        padding: 0 10px;
        margin-right: 10px;
    }

    .visible-xs {
        display: block;
    }

    .hidden-xs {
        display: none;
    }
}

@media screen and (min-width: 900px) {
    .visible-xs {
        display: none;
    }

    .hidden-xs {
        display: block;
    }
}